// ------------------------------------------
// Place any scripts that need to be loaded in the <head> that are
// necessary for use on all pages of the site in this file
// -------------------------------------------

class SiteViewModel {
    constructor() {
        this.formElements = $('form');
        this.document = $(document);

        this.addErrorClass = this.addErrorClass.bind(this);
        this.findFormGroups = this.findFormGroups.bind(this);
        this.toggleSubMenu = this.toggleSubMenu.bind(this);
        this.trackEvents = this.trackEvents.bind(this);
        this.applyBindings = this.applyBindings.bind(this);
        this.highlight = this.highlight.bind(this);
        this.unhighlight = this.unhighlight.bind(this);
        this.init = this.init.bind(this);
    }

    addErrorClass(i, el) {
        if($(el).find('span.field-validation-error').length > 0) {
            $(el).addClass('has-error');
        }
    }

    findFormGroups(i, el) {
        $(el).find('div.form-group').each(this.addErrorClass);
    }

    trackEvents() {
        //Track Downloads for Google Analytics
        const $a = $(this);
        const href = $a.attr('href');
        const label = href;
        if(href != null) {
            let category = '';
            if((href.match(/^http/i)) && (!href.match(document.domain))) {
                category = 'outgoing';
            } else if(href.match(/\.(doc|docx|pdf|xls|xlsx|ppt|pptx|zip|txt|vsd|vxd|js|css|rar|exe|wma|mov|avi|wmv|mp3)$/i)) {
                category = 'download';
            } else if(href.match(/^mailto:/i)) {
                category = 'mailto';
            }

            if(category !== '') {
                ga('send', 'event', category, 'click', label);
                ga(clientTracker + '.send', 'event', category, 'click', label);
            }

        }
    }

    stopDefault(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    hideSubMenus($menuChildren) {
        const $target = 'body, .dropdown-toggle';

        $(document).on('click', $target, function () {
            $menuChildren.removeClass('open');
            $(document).off('click', $target);
        });
    }

    toggleSubMenu(event) {
        this.stopDefault(event);

        const $ele = $(event.currentTarget);
        const $menuChildren = $ele.closest('.dropdown-menu.multi-level').children();

        $menuChildren.removeClass('open');
        $ele.parent().toggleClass('open');

        this.hideSubMenus($menuChildren);
    }

    applyBindings() {
        this.document.on('click', 'a', this.trackEvents);
        this.document.on('click', '.multi-level .SubMenu > .flyout-toggle', this.toggleSubMenu);
    }

    highlight(element) {
        $(element).closest('.form-group').addClass('error');
    }

    unhighlight(element) {
        $(element).closest('.form-group').removeClass('error');
    }

    init() {
        this.formElements.each(this.findFormGroups);
        this.applyBindings();

        if($.validator) {
            $.validator.setDefaults({
                highlight: this.highlight,
                unhighlight: this.unhighlight
            });
        }

        $('.load-content-async').each((index, item) => {
            const url = $(item).data('url');
            $(item).load(url);
        });
    }
}

$(() => {
    const siteViewModel = new SiteViewModel();
    siteViewModel.init(); 
});